import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo";

function Policies() {
  return (
    <Layout>
      <SEO title={"Policies and disclamers"}></SEO>
      <h1>Policies and disclaimers</h1>

      <p>I'm sure you saw that there is no cookie notice, no popups, no ads, no scares.</p>
      <p>As by day I am a web dev, I know that the internet is overwhelming, it's annoying and definitely tries following you around.</p>
      <p>I want to make sure that this little corner here is as hassle-free and concern-free as possible, there's no ads, no cookies, no data collection. I just want to create and inspire.</p>

      <p> Therefore, I do not collect data about you. BUT</p>
      <ul>
        <li>I use <a href="https://plausible.io/privacy-focused-web-analytics" rel={'noopener noreferrer'}>Plausible</a> for analytics, and while it is anonymus and does not even set a cookie for you, I think it's important that you are aware.</li>
        <li>I use <a href="https://www.netlify.com/products/forms/">netlify's form</a> feature for my contact form, where I will recieve what you send me - but will keep your information to myself.</li>
      </ul>

      <p>I at the moment do <i>not yet</i> am using a mailer, but I probably will make a newsletter - eventually. Even that I plan on making it as minimal in data collection as possible.</p>
      <p>I will also add a commenting feature - eventually. I am still researching the best option where we can save the most minimal info about you.</p>
      <p>I am also applying for some affiliate links, which might happen (I'd actually need people to read my posts so...). If it does happen, I'll update this whole accordingly.</p>

    </Layout>
  )
}

export default Policies